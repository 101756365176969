import izmir from '../../assets/img/izmir.png';
import ankara from '../../assets/img/ankara.png';
import mersin from '../../assets/img/mersin.png';
import manisa from '../../assets/img/manisa.png';
import balikesir from '../../assets/img/balikesir.png';
import aydin from '../../assets/img/aydin.png';
import fethiye from '../../assets/img/fethiye.png';
import './frencaises.css';

import maskot from '../../assets/img/maskot-w-bg.png';


export default function Frencaises() {
    return <>
        <section className="w-wrapper" id="subeler">
        <div className="flexColCenter paddings">
        <span className="primaryText">
Şubeler
            </span>
            <span className="greenText"> Bizi ülkemizin 7 farklı kentinde bulabilirsiniz</span>
           
          </div>

            <div className="paddings innerWidth  c-container grid">
                <div className="shop__card">
                    <img src={izmir} alt="image" className="shop__img" />

                    <h3 className="CardTitleText">
                        Sosyal Sanathane <br /> İzmir
                    </h3>

                   
                    <div>
                        <a href="https://www.instagram.com/sosyalsanathane.izmir/" className="TicketButton">
                            <span>
                                <i className="ri-instagram-line"></i>
                            </span>
                        </a>
                        <a href="tel:+905059229212" 
                            
                        class="TicketButton">
                            <span>
                                <i class="ri-phone-line"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="shop__card">
                    <img src={ankara} alt="image" className="shop__img" />

                    <h3 class="CardTitleText">
                        Sosyal Sanathane <br /> Ankara
                    </h3>


                    <div>
                        <a href="https://www.instagram.com/sosyalsanathane.ankara/" class="TicketButton">
                            <span>
                                <i class="ri-instagram-line"></i>
                            </span>
                        </a>
                        <a href="tel:+905453964756" 
                            
                            class="TicketButton">
                                <span>
                                    <i class="ri-phone-line"></i>
                                </span>
                            </a>
                    </div>
                </div>
                <div class="shop__card">
                    <img src={mersin} alt="image" class="shop__img" />

                    <h3 class="CardTitleText">
                        Sosyal Sanathane <br /> Mersin
                    </h3>


                    <div>
                        <a href="https://www.instagram.com/sosyalsanathane.mersin/" class="TicketButton">
                            <span>
                                <i class="ri-instagram-line"></i>
                            </span>
                        </a>
                        <a href="tel:+905059229212" 
                            
                            class="TicketButton">
                                <span>
                                    <i class="ri-phone-line"></i>
                                </span>
                            </a>
                    </div>
                </div>
                <div class="shop__card">
                    <img src={manisa} alt="image" class="shop__img" />

                    <h3 class="CardTitleText">
                        Sosyal Sanathane <br /> Manisa
                    </h3>


                    <div>
                        <a href="https://www.instagram.com/sosyalsanathane.manisa/" class="TicketButton">
                            <span>
                                <i class="ri-instagram-line"></i>
                            </span>
                        </a>
                        <a href="tel:+905074118989" 
                            
                            class="TicketButton">
                                <span>
                                    <i class="ri-phone-line"></i>
                                </span>
                            </a>
                    </div>
                </div>
                <div class="shop__card">
                    <img src={balikesir} alt="image" class="shop__img" />

                    <h3 class="CardTitleText">
                        Sosyal Sanathane <br /> Balıkesir
                    </h3>


                    <div>
                        <a href="https://www.instagram.com/sosyalsanathane.balikesir/" class="TicketButton">
                            <span>
                                <i class="ri-instagram-line"></i>
                            </span>
                        </a>
                        <a href="tel:+905353695190" 
                            
                            class="TicketButton">
                                <span>
                                    <i class="ri-phone-line"></i>
                                </span>
                            </a>
                        
                    </div>
                </div>
                <div class="shop__card">
                    <img src={aydin} alt="image" class="shop__img" />

                    <h3 class="CardTitleText">
                        Sosyal Sanathane <br />Aydın
                    </h3>


                    <div>
                        <a href="https://instagram.com/sosyalsanathane.aydin/" class="TicketButton">
                            <span>
                                <i class="ri-instagram-line"></i>
                            </span>
                        </a>
                        <a href="https://www.tiktok.com/sosyalsanathane.aydin" class="TicketButton">
                            <span>
                                <i class="ri-tiktok-line"></i>
                            </span>
                        </a>
                        <a href="tel:+905453847675" 
                            
                            class="TicketButton">
                                <span>
                                    <i class="ri-phone-line"></i>
                                </span>
                            </a>
                    </div>
                </div>
                <div class="shop__card">
                    <img src={fethiye} alt="image" class="shop__img" />

                    <h3 class="CardTitleText">
                        Sosyal Sanathane <br />Fethiye
                    </h3>


                    <div>
                        <a href="https://instagram.com/sosyalsanathane.fethiye" class="TicketButton">
                            <span>
                                <i class="ri-instagram-line"></i>
                            </span>
                        </a>
                        <a href="https://www.tiktok.com/@sosyalsanathane.f" class="TicketButton">
                            <span>
                                <i class="ri-tiktok-line"></i>
                            </span>
                        </a>
                        <a href="tel:+905079496822" 
                            
                            class="TicketButton">
                                <span>
                                    <i class="ri-phone-line"></i>
                                </span>
                            </a>
                    </div>
                </div>

                <div class="shop__card">
                    <img src={maskot} alt="image" class="shop__img" />

                    <h5 class="CardTitleText">
    Çok Yakında <br /> Tüm Türkiye
                    </h5>


                    <div className=''>
                <a href="">İş birliği İçin: </a>
                       <a href="tel:+905059229212" class="TicketButton">
                            <span>
                                <i class="ri-phone-line"></i>
                            </span>
                        </a>
                        <a href="
                        mailto:sosyalsanathane.izmir@gmail.com
                        " class="TicketButton">
                            <span>
                                <i class="ri-mail-line"></i>
                            </span>
                        </a>
                    </div>
                </div>


            </div>

        </section></>
}