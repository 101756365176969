import React, { useEffect, useState } from "react";
import { db } from "../config";
import { collection, getDocs } from "firebase/firestore";
import EventCard from "../components/event_card";
import Header from "./../sections/header/header";
import Footer from "./footer";
import {  MdFilter9Plus } from "react-icons/md";
import { FaFilter } from "react-icons/fa6";
const cities = ["İzmir", "Ankara", "Mersin", "Aydın", "Manisa", "Balıkesir", "Fethiye"];
const cityCodes = ["35", "06", "33", "09", "45", "10", "48"];
const eventsNames = [
  "Resim",
  "Heykel",
  "Maske",
  "Bez Çanta",
  "Plak Boyama",
];
const eventCodes = [
  "resim",
  "heykel",
  "maske",
  "canta",
  "plak",
];

export default function BuyTicket() {
  const [events, setEvents] = useState([]);
  const [cityFilter, setCityFilter] = useState([
    "35",
  ]);
  const [eventFilter, setEventFilter] = useState([
    ...eventCodes,
  ]);


  
  const getEvents = async () => {
    await getDocs(collection(db, "events")).then((snapshot) => {
      const events = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setEvents(events);
    });
  };

  function handleCityCheckbox(city, checked) {
    if (checked) {
      setCityFilter([...cityFilter, city]);
    } else {
      setCityFilter(cityFilter.filter((cityCode) => cityCode !== city));
    }
  }

  function handleEventCheckbox(event, checked) {
    if (checked) {
      setEventFilter([...eventFilter, event]);
    } else {
      setEventFilter(eventFilter.filter((eventCode) => eventCode !== event));
    }

  }

  function isShown(event) {
    const showCity = event.area && cityFilter.includes(event.area);

    const eventTypes = Array.isArray(event.eventDetail)
      ? event.eventDetail
      : [];

    var showFilter;

    if (eventTypes.length === 0) {
      showFilter = true;
    } else {
      showFilter = eventTypes.some((eventType) =>
        eventFilter.includes(eventType)
      );
    }


    return showFilter && showCity;
  }

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {}, []);


  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
       <Header></Header>
      <br></br>

      <section className="home section paddings innerWidth ">
        {/* progress */}
        <div className="container">
          <div>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{
                  width: "25%",
                }}
              >
                <span className="progress-value">{

                }</span>
              </div>
            </div>
            <div className="avatar-under-progress">
              <h2>1</h2>
            </div>
            <h4 className="under-avatar-progress-text">Etkinlik Seç</h4>
          </div>
        </div>

        {/* progress */}
        <div className="home__con container ">
          <div className="">
          {/* when mobile minimized */}
          <div className={`filterContainer ${isOpen ? 'open' : ''}`}>
      <div className="filterHeader " onClick={toggleOpen}>
       <div className="justify-content">
       <h3>Filtre</h3>  <FaFilter width={'font-size:1rem;'} /> 
       </div>
       
   
      </div>
      <div className="filterContent">
        <ul>
                <br />
          <p className="text-lg">Şehir</p>
          {cityCodes.map((cityCode) => {
            const cityName = cities[cityCodes.indexOf(cityCode)];
            return (
              <label className="checkbox-container" key={cityCode}>
                {cityName}
                <input 
                  type="checkbox" 
                  checked={cityFilter.includes(cityCode)} 
                  onChange={(event) => handleCityCheckbox(cityCode, event.target.checked)} 
                />
                <span className="checkmark"></span>
              </label>
            );
          })}
        </ul>

        <br />
        <ul>
          <p className="text-lg">Etkinlik Tipi</p>
          {eventCodes.map((eventCode) => {
            const eventName = eventsNames[eventCodes.indexOf(eventCode)];
            return (
              <label className="checkbox-container" key={eventCode}>
                {eventName}
                <input 
                  type="checkbox" 
                  checked={eventFilter.includes(eventCode)} 
                  onChange={(event) => handleEventCheckbox(eventCode, event.target.checked)} 
                />
                <span className="checkmark"></span>
              </label>
            );
          })}
        </ul>
      </div>
    </div>
          </div>

          <div className="">
            {events?.map((event, index) => {
              if (isShown(event)) {
                return <EventCard key={index} event={event} />;
              } 
            })}

            {/* filter emty */}
            {events.length === 0 && (
              <div >
                <h2>Henüz etkinlik bulunmamaktadır.</h2>
              </div>
            )}
          </div>
        </div>
      </section>


    </>
  );
}
