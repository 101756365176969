export function getDate(dateString) {
    const months = [
        'Ock', 'Şub', 'Mar', 'Nis', 'May', 'Haz',
        'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'
    ];
    const days = [
        'Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'
    ];

    const date = new Date(dateString);
    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];
    const dayNumber = date.getDate();


    // remove am pm and 24 hour format
    let time = date.toLocaleTimeString('tr-TR', {
        hour: '2-digit',
        minute: '2-digit'
    });

    return {
        dayName: dayName,
        time: time,
        monthName: monthName,
        dayNumber: dayNumber,

    };
}


export function getLowestPrive(prices) {
    let lowestPrice = prices[0];

    prices.forEach((price) => {
        if (price < lowestPrice) {
            lowestPrice = price;
        }
    }
    );
    return lowestPrice;
}

