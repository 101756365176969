import React from "react";
import "./page_not_found.css";
import Header from "../header/header";
import FooterNew from "../footer/footer";
import PNF from '../../assets/img/404.png';

export default function PageNotFound() {
    return (
        <div>
            <Header></Header>
          <div className="innerWidth paddings r-container">
          <div className="flexColCenter ">
          <div className="small-padding"></div>
          <img src={PNF} alt="" className="pnf-img" srcset="" />
          <div className="small-padding"></div>
          <h1 className="pnf-title">404</h1>
            <h1 >SAYFA BULUNAMADI!</h1>
            <div className="small-padding"></div>
            <p className="pnf-description">
            Bu sayfa kaldırılmış ya da değiştirilmiş olabilir. İlginizi çekebilecek diğer içerikler için lütfen ana sayfamızı ziyaret edin.
            </p>
            <div className="small-padding"></div>
            <button  className="button"
            onClick={
                () => {
                    window.location.href = "/";
                }
            }
            >Anasayfaya Dön</button>
          </div>
          </div>
          <FooterNew></FooterNew>
        </div>
    );
}