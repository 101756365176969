import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import BuyTicket from './sections/buy_ticket';
import Purchase from './sections/purchase';
import Payment from './sections/payment';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Signup from './sections/signup';
import BiletiniAl from './sections/biletini-al';

import PageNotFound from './sections/pagenotfound/page_not_found';
import Subeler from './sections/subeler/subeler';



export const UserContext = React.createContext(null);


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/subeler",
    element: <Subeler />,
  },

  {
    path: '/bilet-al',
    element: <BuyTicket />,
  },
  {
    path: '/satin-al',
    element: <Purchase />,
  },
  {
    path: '/odeme',
    element: <Payment />,
  },
  {
    path: 'kayit-ol',
    element: <Signup />,
  },
  {
    path: 'biletini-al',
    element: <BiletiniAl />,
  },
 
]);



ReactDOM.createRoot(document.getElementById("root")).render(

  <React.StrictMode>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </React.StrictMode>
);

function UserProvider({ children })  {
  const [user, setUser] = useState(null);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

reportWebVitals();
