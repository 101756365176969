import { get } from "firebase/database";
import { getDate, getLowestPrive } from "../functions/date";
import { Link } from "react-router-dom";
import { IoAlertCircleSharp } from "react-icons/io5";

export default function EventCard({ event }) {
  console.log(event); // Debug: check the event object
  console.log(event.area); // Debug: check the area value
  console.log(event.eventDetail.length); // Debug: check the eventDetail array length

  // Check if the eventDetail array is empty
  if (event.eventDetail.length === 0) {
    console.log("Event Detail array is empty");
  }

  return (
    <div class="tile">
      <div className="flex ">
        <div class="mk margin-right">
          <div class="text-container">
            <p class="agu-text">{getDate(event.date).monthName}</p>
          </div>
          <div class="text-container2">
            <p class="number-text">19</p>
          </div>
        </div>
        <div>
          <div className="">
            <h3>{event.name}</h3>
            <p>
              {event.address ? event.address : "Adres bilgisi bulunmamaktadır"}
            </p>

            <div className="smallest-padding"></div>
            <p>
              {getDate(event.date).dayName} {event.startTime} - {event.endTime}
            </p>
            <div className="smallest-padding"></div>
            <div className="">
              <label>
                {event.minAge === ""
                  ? "Yaş kısıtlaması bulunmamaktadır"
                  : event.minAge && event.minAge + " yaş ve üzeri"}
              </label>
            </div>
          </div>
          <div className="right-top-corner"></div>

          <div className="small-padding"></div>
          {/* category */}

          {event.eventDetail.map((tag) => {
            return (
              <div className="chip">
                <p>{tag}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="small-padding"></div>
      <div className="space-between">
        
        <p className="last-ticket-title">
          <span>
            <IoAlertCircleSharp style={{color: 
              event.ticketCount > 10 ? "red" : "green",

            }} />
          </span>
          {event.ticketCount} 10 Bilet Kaldı
        </p>
      </div>

      <div className="small-padding"></div>
      <div className="justify-content">
        <span className="">
          <span className="price-text-bold">
            {getLowestPrive([150, 190, 200])} ₺
          </span>

          <span className="price-text">'den <br /> başlayan fiyatlarla</span>
        </span>
        <Link className="" to="/satin-al" state={event}>
          <button className="btn">BİLETİNİ AL</button>
        </Link>
      </div>
      {/* <div className="justify-content">
          <span className="">
            {getLowestPrive([150, 190, 200])} ₺
            <span className="">'den başlayan fiyatlarla</span>
          </span>
         <span>
         <Link className="" to="/satin-al" state={event}>
            <button className="btn">BİLETİNİ AL</button>
          </Link>
         </span>
        </div> */}
    </div>

    // <div className="">
    //         <div className="">
    //             <div>
    //                 <div className=''>
    //                     <div className="">
    //                         <div className="">
    //                             <div className="">
    //                                 <p className="">{getDate(event.date).monthName}</p>
    //                             </div>
    //                             <div className="">
    //                                 <p className="">{getDate(event.date).dayNumber}</p>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className=''>
    //                     <p
    //                         className=''
    //                     >{getDate(event.date).dayName}</p>
    //                 </div>
    //             </div>
    //             <div className="">
    //                 <p className="">
    //                     {event.name}
    //                 </p>
    //                 {/* Location icon with location text */}
    //                 <div className="">

    //                     <label htmlFor="">
    //                         {event.address ? event.address : 'Adres bilgisi bulunmamaktadır'}
    //                     </label>
    //                 </div>
    //                 <div className="">

    //                     <label htmlFor="">
    //                         {event.startTime} - {event.endTime}
    //                     </label>
    //                 </div>

    //                 <div className="">

    //                     <label>
    //                         {event.minAge === "" ? 'Yaş kısıtlaması bulunmamaktadır' : event.minAge && event.minAge + " yaş ve üzeri"}
    //                     </label>
    //                 </div>
    //             </div>
    //         </div>
    //         { /* TODO: Bunlar List mi, Map mi? Firebase'de iki farklı şekilde data var  */}
    //         <div className="">

    //         </div>
    //         <div className="">
    //             <span className="">
    //                 {getLowestPrive(
    //                     [150, 190, 200,]
    //                 )} ₺
    //                 <span className="">
    //                     'den başlayan fiyatlarla
    //                 </span>
    //             </span>
    //             <Link
    //                 to='/satin-al'
    //                 state={event}
    //             >
    //             Satın Al
    //             </Link>
    //         </div>
    //     </div>
  );
}
