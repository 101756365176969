import React from "react";
import Header from "./header/header";
import Footer from "./footer/footer";

export default function BiletiniAl() {
  return (
    <div className="">
      <Header></Header>
      <div className="innerWidth paddings r-container">
        <div className="flexColCenter ">
          <div className=" ">
            <div className="r-head flexColCenter">
              <span className="greenText">Şehirini Seç</span>
              <span className="hero-des">
                Sosyal Sanathane etkinliklerine katılmak için şehirini seç
                biletini al!
              </span>
            </div>
          </div>
        </div>

        <div className="cityLinkContainer">
          <div className=" cityLinkFlexFloating flexCenter">
            <div className="small-padding "></div>
            <a
              className="citylinkbuttonRouter "
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-izmir";
              }}
            >
              İzmir
              <span>35</span>
            </a>
            <a
              className="citylinkbuttonRouter "
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-ankara";
              }}
            >
              Ankara
              <span>06</span>
            </a>
            <a
              className="citylinkbuttonRouter"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-mersin";
              }}
            >
              Mersin
              <span>33</span>
            </a>
            <a
              className="citylinkbuttonRouter"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-aydin";
              }}
            >
              Aydın
              <span>09</span>
            </a>
            <a
              className="citylinkbuttonRouter"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-manisa";
              }}
            >
              Manisa
              <span>45</span>
            </a>
            <a
              className="citylinkbuttonRouter"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-balikesir";
              }}
            >
              Balıkesir
              <span>10</span>
            </a>
            <a
              className="citylinkbuttonRouter"
              onClick={() => {
                window.location.href =
                  "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-fethiye";
              }}
            >
              Fethiye
              <span>48</span>
            </a>
          </div>
        </div>
      </div>

      <div className="paddings  flexCenter hero-container">
        <div className="flexColCenter innerWidth">
          <h3>Instagram</h3>
          {/* instagram accont 7 diffrent */}
          <div className="flexColStart ">
            <div className="small-padding">
              <a href="https://www.instagram.com/sosyalsanathane.izmir/">
                @sosyalsanathane.izmir
              </a>
            </div>
            <div className="small-padding">
              <a href="https://www.instagram.com/sosyalsanathane.ankara/">
                @sosyalsanathane.ankara
              </a>
            </div>
            <div className="small-padding">
              <a href="https://www.instagram.com/sosyalsanathane.mersin/">
                @sosyalsanathane.mersin
              </a>
            </div>
            <div className="small-padding">
              <a href="https://www.instagram.com/sosyalsanathane.aydin/">
                @sosyalsanathane.aydin
              </a>
            </div>
            <div className="small-padding">
              <a href="https://www.instagram.com/sosyalsanathane.manisa/">
                @sosyalsanathane.manisa
              </a>
            </div>
            <div className="small-padding">
              <a href="https://www.instagram.com/sosyalsanathane.balikesir/">
                @sosyalsanathane.balikesir
              </a>
            </div>
            <div className="small-padding">
              <a href="https://www.instagram.com/sosyalsanathane.fethiye/">
                @sosyalsanathane.fethiye
              </a>
            </div>
          </div>
        </div>

        {/* right side */}
        
      </div>
      <br />
      <br />
      <br />
      <Footer></Footer>
    </div>
  );
}
