import React from "react";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import data from "../../utils/slider.json";
import "./features.css";
import "swiper/css"
import { sliderSettings } from "../../utils/common";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { FaFilter } from "react-icons/fa6";


export default function Features() {
  // hide and show descriptions
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  
 
  return (
    <>
      <section className="r-wrapper" id="etkinlikler">
        <div className="paddings innerWidth r-container">
          <div className="r-head flexColStart">
            <span className="greenText">Etkinliklerimiz</span>
            <span className="primaryText">Etkinlik Çeşitleri</span>
          </div>

          <Swiper 
          {...sliderSettings}
          >
         
            {data.map((card, i) => (
              <SwiperSlide key={i}>
                <div className="flexColStart r-card">
                    <img src={card.image} alt={card.name}  className="img-features"/>
                  <span>
                    <span style={{color: "green"}}></span>
                    <span></span>
                  </span>
                    <span className="TitleText">{card.name}</span>
                    <span className=""></span>

                </div>
              </SwiperSlide>
            ))}
         <div>
         <SlideNextButton />
         </div>
          </Swiper>
         
        </div>
      </section>
    </>
  );
}



const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <div className="flexCenter r-buttons paddings">
      <button onClick={() => swiper.slidePrev()} className="r-prevButton">
        &lt;
      </button>
      <button onClick={() => swiper.slideNext()} className="r-nextButton">
        &gt;
      </button>
    </div>
  );
};