import React from "react";
import "./subeler.css";
import Header from "../header/header";
import FooterNew from "../footer/footer";
import Bostanlı from "../../assets/img/bostanli-ss.jpg";
import Alsancak from "../../assets/img/alsancak sube.png";
import İstinyePark from "../../assets/img/istinye-sss.png";
import izmir from "../../assets/img/izmir.png";
import ankara from "../../assets/img/ankara.png";
import mersin from "../../assets/img/mersin.png";
import manisa from "../../assets/img/manisa.png";
import balikesir from "../../assets/img/balikesir.png";
import aydin from "../../assets/img/aydin.png";
import fethiye from "../../assets/img/fethiye.png";
import maskot from "../../assets/img/maskot-w-bg.png";
import { MdLocationOn, MdPhone } from "react-icons/md";

export default function Subeler() {
  return (
    <div>
      <Header></Header>
      <div className="innerWidth paddings r-container container">
        {/* sss */}
        <div className="flexColCenter paddings">
          <span className="primaryText">Şubeler</span>
          <span className="greenText">
            {" "}
            Bizi ülkemizin 7 farklı kentinde bulabilirsiniz
          </span>
        </div>

        <div className="paddings innerWidth  c-container grid">
          <div className="shop__card">
            <img src={izmir} alt="image" className="shop__img" />

            <h3 className="CardTitleText">
              Sosyal Sanathane <br /> İzmir
            </h3>

            <div>
              <a
                href="https://www.instagram.com/sosyalsanathane.izmir/"
                className="TicketButton"
              >
                <span>
                  <i className="ri-instagram-line"></i>
                </span>
              </a>
              <a href="tel:+905059229212" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="shop__card">
            <img src={ankara} alt="image" className="shop__img" />

            <h3 class="CardTitleText">
              Sosyal Sanathane <br /> Ankara
            </h3>

            <div>
              <a
                href="https://www.instagram.com/sosyalsanathane.ankara/"
                class="TicketButton"
              >
                <span>
                  <i class="ri-instagram-line"></i>
                </span>
              </a>
              <a href="tel:+905453964756" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
              <a href=""></a>
            </div>
          </div>
          <div class="shop__card">
            <img src={mersin} alt="image" class="shop__img" />

            <h3 class="CardTitleText">
              Sosyal Sanathane <br /> Mersin
            </h3>

            <div>
              <a
                href="https://www.instagram.com/sosyalsanathane.mersin/"
                class="TicketButton"
              >
                <span>
                  <i class="ri-instagram-line"></i>
                </span>
              </a>
              <a href="tel:+905059229212" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
            </div>
          </div>
          <div class="shop__card">
            <img src={manisa} alt="image" class="shop__img" />

            <h3 class="CardTitleText">
              Sosyal Sanathane <br /> Manisa
            </h3>

            <div>
              <a
                href="https://www.instagram.com/sosyalsanathane.manisa/"
                class="TicketButton"
              >
                <span>
                  <i class="ri-instagram-line"></i>
                </span>
              </a>
              <a href="tel:+905074118989" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
            </div>
          </div>
          <div class="shop__card">
            <img src={balikesir} alt="image" class="shop__img" />

            <h3 class="CardTitleText">
              Sosyal Sanathane <br /> Balıkesir
            </h3>

            <div>
              <a
                href="https://www.instagram.com/sosyalsanathane.balikesir/"
                class="TicketButton"
              >
                <span>
                  <i class="ri-instagram-line"></i>
                </span>
              </a>
              <a href="tel:+905353695190" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
            </div>
          </div>
          <div class="shop__card">
            <img src={aydin} alt="image" class="shop__img" />

            <h3 class="CardTitleText">
              Sosyal Sanathane <br />
              Aydın
            </h3>

            <div>
              <a
                href="https://instagram.com/sosyalsanathane.aydin/"
                class="TicketButton"
              >
                <span>
                  <i class="ri-instagram-line"></i>
                </span>
              </a>
              <a
                href="https://www.tiktok.com/sosyalsanathane.aydin"
                class="TicketButton"
              >
                <span>
                  <i class="ri-tiktok-line"></i>
                </span>
              </a>
              <a href="tel:+905453847675" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
            </div>
          </div>
          <div class="shop__card">
            <img src={fethiye} alt="image" class="shop__img" />

            <h3 class="CardTitleText">
              Sosyal Sanathane <br />
              Fethiye
            </h3>

            <div>
              <a
                href="https://instagram.com/sosyalsanathane.fethiye"
                class="TicketButton"
              >
                <span>
                  <i class="ri-instagram-line"></i>
                </span>
              </a>
              <a
                href="https://www.tiktok.com/@sosyalsanathane.f"
                class="TicketButton"
              >
                <span>
                  <i class="ri-tiktok-line"></i>
                </span>
              </a>
              <a href="tel:+905079496822" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
            </div>
          </div>
          <div class="shop__card">
            <img src={maskot} alt="image" class="shop__img" />

            <h5 class="CardTitleText">
              Çok Yakında <br /> Tüm Türkiye
            </h5>

            <div>
              <a href="">İş birliği İçin: </a>
              <a href="tel:+905059229212" class="TicketButton">
                <span>
                  <i class="ri-phone-line"></i>
                </span>
              </a>
              <a
                href="mailto:sosyalsanathane.izmir@gmail.com"
                class="TicketButton"
              >
                <span>
                  <i class="ri-mail-line"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="small-padding"></div>
        <div className="flexColCenter">
          <div className="small-padding"></div>
          <span className="primaryText">Merkez Mekanlarımız</span>
          <span className="greenText">
            {" "}
            İzmir'in üç farklı lokasyonunda bizi bulabilirsiniz.
          </span>
          <div className="small-padding"></div>
          <div className="sube-grid">
            <div className="">
              <img className="sube-img" src={Alsancak} alt="" srcset=""></img>
              <div className="small-padding"></div>
              <h2 className="TitleText">Alsancak Şube</h2>
              <br />
              <div className="flex ">
              <a  onClick={
                    () => {
                      window.open('https://www.google.com/maps?sca_esv=054ae6cb572344c8&sca_upv=1&iflsig=AL9hbdgAAAAAZnQboS3ckKF7EtOHpHa1ZwLtsELzQE-a&gs_lp=Egdnd3Mtd2l6IhBzb3N5YWwgc2FuYXRoYW5lKgIIADILEC4YgAQYxwEYrwEyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDILEC4YgAQYxwEYrwFIsxFQAFjmDXAAeACQAQCYAe4BoAHoEKoBBjAuMTQuMbgBA8gBAPgBAZgCD6AChBHCAgsQABiABBixAxiDAcICCBAAGIAEGLEDwgIEEAAYA8ICCBAuGIAEGLEDwgILEAAYgAQYsQMYigWYAwCSBwYwLjE0LjGgB4yNAQ&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KQHrDWxx2bsUMdRfksx7ye8p&daddr=Alsancak,+1458.+Sk.+No:+8,+35220+Konak/%C4%B0zmir', '_blank');
                    }
                  }>
                <div className="flexCenter button">
                  <MdLocationOn />
                  <div className="smallest-padding"></div>
                  Konuma Git
                </div>
              </a>
              <div className="small-padding"></div>
              <a href="tel:+905059229212">
                <div className="flexCenter button-white">
                  <MdPhone />
                  <div className="smallest-padding"></div>
                  İletişim
                </div>
              </a>
              </div>
             
            </div>
            <div className="">
              <img className="sube-img" src={Bostanlı} alt="" srcset=""></img>
              <div className="small-padding"></div>
              <h2 className="TitleText">Bostanlı Şube</h2>
              <br />
             
              <div className="flex ">
              <a onClick={
                  () => {
                    window.open('https://www.google.com/maps/dir/37.8568704,27.8298624/sosyal+sanathane+bostanl%C4%B1/@38.1643517,26.8120901,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14bbd9e4a78122e3:0xfa481db50e54b7e4!2m2!1d27.09913!2d38.461251?entry=ttu', '_blank');
                  }
                }>
                <div className="flexCenter button">
                  <MdLocationOn />
                  <div className="smallest-padding"></div>
                  Konuma Git
                </div>
              </a>
              <div className="small-padding"></div>
              <a href="tel:+905059229212">
                <div className="flexCenter button-white">
                  <MdPhone />
                  <div className="smallest-padding"></div>
                  İletişim
                </div>
              </a>
              </div>
            </div>
            <div className="">
              <img
                className="sube-img"
                src={İstinyePark}
                alt=""
                srcset=""
              ></img>
              <div className="small-padding"></div>
              <h2 className="TitleText">İstinyePark Teras Şube</h2>

              <br />
           

              <div className="flex ">
              <a onClick={
                  () => {
                    window.open('https://www.google.com/maps?sca_esv=ba085665fbb45bd4&sca_upv=1&gs_lp=Egxnd3Mtd2l6LXNlcnAiBmlzdGlueSoCCAAyEBAAGIAEGLEDGEMYgwEYigUyDRAAGIAEGLEDGEMYigUyCxAuGIAEGMcBGK8BMgsQABiABBixAxiDATILEAAYgAQYsQMYgwEyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAAGIAEGLEDGIMBMgsQLhiABBixAxiDAUjDKFDxAlifJHAEeACQAQCYAdgBoAGdDaoBBTAuOS4xuAEDyAEA-AEBmAINoALCDagCCsICBRAhGKABwgIWEAAYAxi0AhjlAhjqAhiMAxiPAdgBAcICFhAuGAMYtAIY5QIY6gIYjAMYjwHYAQHCAgoQABiABBhDGIoFwgIQEC4YgAQYQxjHARiKBRivAcICDhAuGIAEGLEDGIMBGIoFwgIFEC4YgATCAggQABiABBixA8ICDRAuGIAEGLEDGEMYigXCAg4QLhiABBixAxjRAxjHAZgDA4gGAboGBAgBGAqSBwUzLjkuMaAH5Vw&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KWMowr0S3bsUMfBganAtG3Ag&daddr=BAH%C3%87ELERARASI+MH+%C5%9EEH%C4%B0T+B%C4%B0NBA%C5%9EI+AL%C4%B0+RESM%C4%B0+TUFAN+SOK.+NO:3,+35330+Bal%C3%A7ova/%C4%B0zmir', '_blank');
                  }
                }>
                <div className="flexCenter button">
                  <MdLocationOn />
                  <div className="smallest-padding"></div>
                  Konuma Git
                </div>
              </a>
              <div className="small-padding"></div>
              <a href="tel:+905059229212">
                <div className="flexCenter button-white">
                  <MdPhone />
                  <div className="smallest-padding"></div>
                  İletişim
                </div>
              </a>
              </div>
           
             
            </div>
          </div>
        </div>






        
      </div>

      <FooterNew></FooterNew>
    </div>
  );
}
