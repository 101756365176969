import ss_img from "../../assets/img/ss-img.jpeg";
import "./contact.css";
import icon from "../../assets/img/icon.png";
export default function Contact() {
  return (
    <>
    
      <section className="r-wrapper " id="iletisim">
   
        <div className="paddings innerWidth flexCenter r-container">
          <div className=" ">
            
            <span className="greenText2 primaryText">İletişim</span>
          
          
          </div>
        </div>
        <div className=" innerWidth flexStart c-container">
          
          <div className=" paddings">
            <div className="contact__content">
              <div className="Start">
              <h2 className="flexStart">
      Bize Yazın
                </h2>
                <p className="flexStart">
                  Sizlere en iyi hizmeti sunabilmemiz için bize ulaşın.
                </p>

                <div className="small-padding"></div>

                <div className="flexStart">
                


                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-field"
                  placeholder="İsim Soyisim"
                  required
                />


              </div>
      <div className="small-padding"></div>
              <div className="flexStart">
              <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-field"
                  placeholder="Telefon Numarası"
                  required
                />
              </div>
              <div className="small-padding"></div>
              <div className="flexStart">
              <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-field"
                  placeholder="Mesajınız..."

                  required
                  
                  maxLength={1000}

                  style={{ minHeight: 'calc(1em * 9)', maxHeight: 'calc(1em * 1)' 


                  }}
                />
              </div>
             
              </div>
              
            <br />
            <div className="flexStart">
            <a href="" className="button w-full">
              Gönder
            </a>
            </div>
              
             
             </div>
          </div>

          <div className="flexEnd">
            <img src={ss_img} alt="" className="img-about" />
          </div>
        </div>
      </section>
    </>
  );
}
