import sosyal_light from '../assets/img/sosyal_light.png';

export default function Footer() {
    return <>
        <footer className="footer">
            <div className="footer__container container grid">
                <div>
                    <a href="#" className="nav__logo">
                        <div>
                            <img
                                className="nav__logo"
                                src={sosyal_light}
                                alt=""
                                srcSet=""
                                width={100}
                            />
                        </div>
                    </a>
                    <p className="footer__description">
                        <br />
                        Sanat ve sosyalleşmeyi aynı <br />
                        menüde bulabileceğiniz <br />
                        <span> bir sanat etkinlikleri platformu.</span>
                    </p>
                </div>
                <div className="footer__content grid">
                    <div>
                        <h3 className="footer__title">Sayfalar</h3>
                        <ul className="footer__links">
                            <li>
                                <a href="#etkinlik-cesitleri" className="footer__link">
                                    Etkinlik Çeşitleri
                                </a>
                            </li>
                            <li>
                                <a href="#hakkimizda" className="footer__link">
                                    Hakkımızda
                                </a>
                            </li>
                            <li>
                                <a href="#subeler" className="footer__link">
                                    Şubeler
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="footer__title">Bilgi İletişim</h3>
                        <ul className="footer__links">
                            <li>
                                <a href="#uygulama" className="footer__link">
                                    Uygulama
                                </a>
                            </li>
                            <li>
                                <a href="#iletisim" className="footer__link">
                                    İletişim
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="footer__title">SOSYAL MEDYA</h3>
                        <div className="footer__social">
                           
                            {/* The Modal */}
                            <div id="myModal" className="modal">
                                {/* Modal content */}
                                <div className="modal-content">

                                    <ul className="">
                                        
                                        <br />
                                        <li>
                                            <a
                                                href="https://www.instagram.com/sosyalsanathane.izmir/"
                                                target="_blank"
                                            >
                                                @sosyalsanathane.izmir
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/sosyalsanathane.ankara/"
                                                target="_blank"
                                            >
                                                @sosyalsanathane.ankara
                                            </a>
                                        </li>
                                        <li>
                                            {" "}
                                            <a
                                                href="https://www.instagram.com/sosyalsanathane.mersin/"
                                                target="_blank"
                                            >
                                                @sosyalsanathane.mersin
                                            </a>
                                        </li>
                                        <li>
                                            {" "}
                                            <a
                                                href="https://www.instagram.com/sosyalsanathane.manisa/"
                                                target="_blank
  "
                                            >
                                                @sosyalsanathane.manisa
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/sosyalsanathane.balikesir/"
                                                target="_blank"
                                            >
                                                @sosyalsanathane.balikesir
                                            </a>
                                        </li>
                                        <li>
                                            {" "}
                                            <a
                                                href="https://www.instagram.com/sosyalsanathane.aydin/"
                                                target="_blank"
                                            >
                                                @sosyalsanathane.aydin
                                            </a>
                                        </li>
                                        <li>
                                            {" "}
                                            <a
                                                href="https://www.instagram.com/sosyalsanathane.fethiye/"
                                                target="_blank"
                                            >
                                                @sosyalsanathane.fethiye
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <span className="footer__copy">
                © Sosyal Sanathane 2024 Tüm Hakları Saklıdır.
            </span>
        </footer>
    </>
}