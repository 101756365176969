import Footer from "./footer";
import Header from "./../sections/header/header";
import { useLocation } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import iyzico from "../assets/img/iyzico_ile_ode_horizontal_white.png";
import './payment.css';
import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

export default function Payment() {
  const [className, setClassName] = useState(null);
  const location = useLocation();
  const totalPrice = location.state.totalPrice;
  const selectedTickets = location.state.selectedTickets;
  const prices = location.state.prices;

  return (
    <>
      <Header />
      <section className="home section paddings innerWidth">
        <div className="container">
          <div className="progress-container">
            <div
              className="progress-bar"
              style={{
                width: "75%",
              }}
            >
              <span className="progress-value">{}</span>
            </div>
          </div>
          <div className="avatar-under-progress3">
            <h2>3</h2>
          </div>
          <h4 className="under-avatar-progress-text3">Ödeme Yap</h4>
        </div>

        <div className="home__con2 container  ">
          <div className="">
            <div className="flex justify-between items-center">
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                Satın Alım Bilgileri
              </h1>
            </div>
            <div className="">
              <div className="">
                <div className=" justify-between">
                  {Array.from(new Set(selectedTickets)).map((ticket) => {
                    const count = selectedTickets.filter(
                      (selectedTicket) => selectedTicket === ticket
                    ).length;
                    return (
                      <>
                        <div className="flex">
                          <div className="tile justify-content">
                            <div>
                              <h5 className="uppercase">{ticket}</h5>
                            </div>
                            <div className="small-padding"></div>
                            <div>
                              <h5>x {count}</h5>
                            </div>

                            <div>
                              <h4>{prices[ticket] * count} ₺</h4>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="tile">

              <div className=" justify-between">
                <div className="flexStart">
                  <div>
                    <label className="">Etkinlik Kuralları</label>
                 
                    <div className="smallest-padding"></div>
                    <dir></dir>
                    <div>

                     <div className="smallest-padding"></div>
                    </div>
                   
                  </div>
                </div>
                <div className="smallest-padding"></div>
               <div>
               <label className="flexEnd">Bilet Bedeli Vergiler Dahil</label>
                <div className="flexEnd flex">
                  
                  <br />
                  <h4> Toplam</h4>
                  <h4 >{totalPrice} ₺</h4>
                </div>
               </div>
              </div>
            </div>

            
          </div>
          <div className="filterContainer">
            <div>
              <h4>Kişisel Bilgiler</h4>
              <br />
              <div>
                <label className="">Ad Soyad</label>
                <div className="smallest-padding"></div>
                <dir></dir>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-field"
                  placeholder="Ad Soyad"
                />
              </div>
              <div className="small-padding"></div>
              <div>
                <label className="">E-Mail</label>
                <div className="smallest-padding"></div>
                <dir></dir>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-field"
                  placeholder="ornek@ornek.com"
                />
              </div>
              <div className="small-padding"></div>
              <div>
                <label className="">Telefon Numarası</label>
                <div className="smallest-padding"></div>
                <dir></dir>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-field"
                  placeholder="05453847675"
                />
              </div>
            </div>

            <div className="">
              <br />
              <h4>Kart Bilgileri</h4>
              <br />
              <div>
                <label className="">Ad Soyad</label>
                <div className="smallest-padding"></div>
                <dir></dir>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-field"
                  placeholder="Ad Soyad"
                />
              </div>
            </div>
            <div className="small-padding"></div>
            <div>
              <div>
                <p className="text-lg text-gray-700 dark:text-white">
                  Kart Numarası
                </p>
                <div className="smallest-padding"></div>
                <input
                  type="text"
                  name="card-number"
                  id="card-number"
                  className="form-field"
                  placeholder="Kart Numarası"
                />
              </div>
            </div>
            <div className="small-padding"></div>

            <div className="flex">
              <div>
                <div>
                  <p className="text-lg text-gray-700 dark:text-white">
                    Son Kullanma Tarihi
                  </p>
                  <div className="smallest-padding"></div>
                  <input
                    type="text"
                    name="expiry-date"
                    id="expiry-date"
                    class="form-field"
                    placeholder="MM/YY"
                    pattern="^(0[1-9]|1[0-2])\/?([0-9]{2})$"
                    maxLength={4}
                    required
                  />
                </div>
              </div>
              <div className="small-padding"></div>
              <div>
                <div>
                  <p>CVV</p>
                  <div className="smallest-padding"></div>
                  <input
                    type="text"
                    name="cvv"
                    id="cvv"
                    className="form-field"
                    placeholder="***"
                    pattern="^\d{3,4}$"
                    maxLength={3}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="small-padding"></div>
              <input
                className=""
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              ></input>
              <a for="vehicle1">
                {" "}
                <a>Mesafeli Satış Sözleşmesini</a> Okudum Onaylıyorum.{" "}
              </a>
            </div>
            <div className="small-padding"></div>
            <p>
              Kartınızı Visa Tek Tıkla Öde altyapısına kaydedebilmek için üyelik
              bilgilerimden cep telefon numaranızı doğrulayabilirsiniz.
            </p>

            <div className="small-padding"></div>
            <div className="justify-content-end">
              <button
                className="button "
                onClick={async () => {
                  const functions = getFunctions();
                  const makePurchase = httpsCallable(functions, "makePurchase");
                  const response = await makePurchase({
                    locale: "en",
                    conversationId: "deviyzico",
                    price: totalPrice,
                    paidPrice: totalPrice,
                    currency: "TRY",
                    installment: 1,
                    paymentChannel: "WEB",
                    basketId: "B67832",
                    paymentGroup: "PRODUCT",
                    paymentCard: {
                      cardHolderName: "Mehmet Test",
                      cardNumber: "5526080000000006",
                      expireYear: "2028",
                      expireMonth: "11",
                      cvc: "245",
                      registerCard: 0,
                    },
                    buyer: {
                      id: "BY789",
                      name: "John",
                      surname: "Doe",
                      identityNumber: "11111111111",
                      email: "test@testtt.com",
                      gsmNumber: "+905393623333",
                      registrationDate: "2013-04-21 15:12:09",
                      lastLoginDate: "2015-10-05 12:43:35",
                      registrationAddress:
                        "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
                      city: "Istanbul",
                      country: "Turkey",
                      zipCode: "34732",
                      ip: "85.34.78.112",
                    },
                    shippingAddress: {
                      address:
                        "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
                      zipCode: "34742",
                      contactName: "Jane Doe",
                      city: "Istanbul",
                      country: "Turkey",
                    },
                    billingAddress: {
                      address:
                        "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
                      zipCode: "34742",
                      contactName: "Jane Doe",
                      city: "Istanbul",
                      country: "Turkey",
                    },
                    basketItems: Array.from(new Set(selectedTickets)).map(
                      (ticket) => {
                        const count = selectedTickets.filter(
                          (selectedTicket) => selectedTicket === ticket
                        ).length;
                        return {
                          id: ticket,
                          price: prices[ticket] * count,
                          name: ticket,
                          category1: "Collectibles",
                          category2: "Accessories",
                          itemType: "PHYSICAL",
                        };
                      }
                    ),
                  });
                  console.log(response);
                }}
              >
                ÖDEME YAP
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
