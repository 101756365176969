import React from "react";
import "./footer.css";
import logo from "../../assets/img/sosyal_light.png";

const FooterNew = () => {
  return (
    <div className="f-wrapper">
      <div className="paddings innerWidth flexCenter f-container">
        {/* left side */}
        <div className="flexColStart f-left">
          <img src={logo} alt="" width={120} />
          <span className="secondaryText">
            Şehirin her yerinde rastlayabileceğin <br />
            sanat platformu. Sanatla
             sosyalleşme <br /> alanları yaratıyoruz.
          </span>
        </div>

        <div className="flexColStart f-right">

          <span className="hero-des">© Sosyal Sanathane 2024 Tüm Hakları Saklıdır.</span>
          <div className="flexCenter f-menu">
            <span><a href="#etkinlikler">
            Etkinlikler
                </a></span>
            <span><a href="#hakkimizda">
            Hakkımızda
                </a></span>
            <span><a href="#subeler">
            Şubeler
                </a></span>
            <span><a href="">
                <a href="#sss">
                    SSS
                </a>
                </a></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;