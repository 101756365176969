import { HiQuestionMarkCircle } from "react-icons/hi";

const data = [
  {

    heading: "Etkinliklere nasıl katılabilirim?",
    detail:
      "Tüm etkinliklerimiz biletlidir. Online ödeme yolu ile biletinizi alabilir ve böylece rezervasyonunuzu oluşturabilirsiniz. Etkinlik vaktinde etkinlik alanında bulunmanız ve girişte biletinizin ekran görüntüsünü göstermeniz yeterli olacaktır.",
  },
  {

    heading: "Etkinliklerden nasıl haberdar olabilirim?",
    detail: "Instagram platformunda bulunan resmi hesaplarımız üzerinden yayınladığımız etkinlik takvimlerimizi inceleyebilir ve size uygun etkinlik, gün, saat ve mekânı seçerek biletinizi alabilirsiniz.",
  },
 
  {

    heading: "Deneyimim yok. Yardımcı oluyor musunuz?",
    detail:
      "Tüm etkinliklerimiz ilk kez deneyimleyenler dahil herkes için uygun. Etkinlik sırasında size yardımcı olmak üzere ekibimiz yanınızda olacak. Çalışmaları büyük ölçüde sizler oluşturuyorken; zorlandığınız ve desteğe ihtiyacınız olduğu durumlarda biz orada olacağız.",
  },
  {

    heading: "Bu alanlarda yetenekli değilim. Katılabilir miyim?",
    detail:
      "Profesyonel eserler oluşturmayı hedeflemediğimiz için yetenek veya yatkınlık gerekmiyor. Tüm etkinliklerimizde odağımız kaliteli vakit geçirmek ve yaptığımız çalışmadan değil süreçten mutlu olmak. Eğlence odaklı olan etkinliklerimizde maksat bir şeyler üretmek ve bunun tadına varmak. Tabii ki katılabilirsiniz.",
  },
  {

    heading: "Herhangi bir malzeme getirmem gerekiyor mu?",
    detail:
      "Tüm etkinliklerimiz ilk kez deneyimleyenler dahil herkes için uygun. Etkinlik sırasında size yardımcı olmak üzere ekibimiz yanınızda olacak. Çalışmaları büyük ölçüde sizler oluşturuyorken; zorlandığınız ve desteğe ihtiyacınız olduğu durumlarda biz orada olacağız.",
  },
];
export default data;