import React from "react";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import { motion } from "framer-motion";
import "./mobileapp.css"; 
import mobileapp from "../../assets/img/mobile-mockup.png";
import { MdCancel, MdPalette, MdAirplaneTicket, MdChat } from "react-icons/md";
import google_play_badge_tr from '../../assets/img/app-store.webp';
import app_store_badge_tr from '../../assets/img/google-play.webp';
const MobileApp = () => {
  return (
    <div className="blackbg" id="mobil">
      <section className="paddings wrapper">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="innerWidth containers"
      >
        <div className="upperElements paddings">
          <motion.span className="primaryText whiteColor" variants={fadeIn("right", "tween", 0.2, 1)}>
            Sosyal 
            Sanathane
            <br />
           Mobil Uygulama
          </motion.span>
          <motion.span className="secondaryText experience" variants={fadeIn("left", "tween", 0.4, 1)}>
          <div className="primaryText whiteColor" >
            <MdPalette />
            
          </div>
            <div className="secondaryTextHigh ">
              <div>Sosyalleşme ve</div>
              <div>Sanat İçerikleri</div>
            </div>
          </motion.span>
        </div>

        <motion.div
          variants={fadeIn("up", "tween", 0.3, 1)}
          className="person"
        >
          <img src={mobileapp}  alt="" />
        </motion.div>

       

        <div className="lowerElements paddings">
          <motion.div variants={fadeIn("right", "tween", 0.3, 1)} className="experience">
            <div className="primaryText whiteColor">
              <MdChat/>
            </div>
            <div className="secondaryTextHigh">
              <div>Sohbet</div>
              <div>Sosyal Medya</div>
            </div>
          </motion.div>

          <motion.div variants={fadeIn("left", "tween", 0.5, 1)} className="experience">
          <div className="primaryText whiteColor">
<MdAirplaneTicket />
          </div>
            <div className="secondaryTextHigh">
              <div>Online</div>
              <div>Bilet Satışı</div>
            </div>
          </motion.div>

          

          <motion.div variants={fadeIn("left", "tween", 0.5, 1)} className="certificate">

            <img src={google_play_badge_tr} alt="" />
            <div className="smallest-padding"></div>
            <img src={app_store_badge_tr}alt="" />
            
          </motion.div>
        </div>
      </motion.div>
    </section>
    </div>
    
  );
};

export default MobileApp;
