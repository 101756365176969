
import React from "react";
import Bostanlı from "../../assets/img/bostanli-ss.jpg";
import Alsancak from "../../assets/img/alsancak sube.png";
import İstinyePark from "../../assets/img/istinye-sss.png";
import izmir from "../../assets/img/izmir.png";
import ankara from "../../assets/img/ankara.png";
import mersin from "../../assets/img/mersin.png";
import manisa from "../../assets/img/manisa.png";
import balikesir from "../../assets/img/balikesir.png";
import aydin from "../../assets/img/aydin.png";
import fethiye from "../../assets/img/fethiye.png";
import maskot from "../../assets/img/maskot-w-bg.png";
import { MdLocationOn, MdPhone } from "react-icons/md";


export default function Mekanlar() {
    return (
        <div className="innerWidth paddings r-container container" id="mekanlar">
             <div className="flexColCenter ">
          <div className="small-padding"></div>
          <span className="primaryText">Merkez Mekanlarımız</span>
          <span className="greenText">
            {" "}
            İzmir'in üç farklı lokasyonunda bizi bulabilirsiniz.
          </span>
          <div className="small-padding"></div>
          <div className="sube-grid">
            <div className="">
              <img className="sube-img" src={Alsancak} alt="" srcset=""></img>
              <div className="small-padding"></div>
              <h2 className="TitleText">Alsancak Şube</h2>
              <br />
              <div className="flex ">
              <a  onClick={
                    () => {
                      window.open('https://www.google.com/maps?sca_esv=054ae6cb572344c8&sca_upv=1&iflsig=AL9hbdgAAAAAZnQboS3ckKF7EtOHpHa1ZwLtsELzQE-a&gs_lp=Egdnd3Mtd2l6IhBzb3N5YWwgc2FuYXRoYW5lKgIIADILEC4YgAQYxwEYrwEyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDILEC4YgAQYxwEYrwFIsxFQAFjmDXAAeACQAQCYAe4BoAHoEKoBBjAuMTQuMbgBA8gBAPgBAZgCD6AChBHCAgsQABiABBixAxiDAcICCBAAGIAEGLEDwgIEEAAYA8ICCBAuGIAEGLEDwgILEAAYgAQYsQMYigWYAwCSBwYwLjE0LjGgB4yNAQ&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KQHrDWxx2bsUMdRfksx7ye8p&daddr=Alsancak,+1458.+Sk.+No:+8,+35220+Konak/%C4%B0zmir', '_blank');
                    }
                  }>
                <div className="flexCenter button">
                  <MdLocationOn />
                  <div className="smallest-padding"></div>
                  Konuma Git
                </div>
              </a>
              <div className="small-padding"></div>
              <a href="tel:+905059229212">
                <div className="flexCenter button-white">
                  <MdPhone />
                  <div className="smallest-padding"></div>
                  İletişim
                </div>
              </a>
              </div>
             
            </div>
            <div className="">
              <img className="sube-img" src={Bostanlı} alt="" srcset=""></img>
              <div className="small-padding"></div>
              <h2 className="TitleText">Bostanlı Şube</h2>
              <br />
             
              <div className="flex ">
              <a onClick={
                  () => {
                    window.open('https://www.google.com/maps/dir/37.8568704,27.8298624/sosyal+sanathane+bostanl%C4%B1/@38.1643517,26.8120901,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14bbd9e4a78122e3:0xfa481db50e54b7e4!2m2!1d27.09913!2d38.461251?entry=ttu', '_blank');
                  }
                }>
                <div className="flexCenter button">
                  <MdLocationOn />
                  <div className="smallest-padding"></div>
                  Konuma Git
                </div>
              </a>
              <div className="small-padding"></div>
              <a href="tel:+905059229212">
                <div className="flexCenter button-white">
                  <MdPhone />
                  <div className="smallest-padding"></div>
                  İletişim
                </div>
              </a>
              </div>
            </div>
            <div className="">
              <img
                className="sube-img"
                src={İstinyePark}
                alt=""
                srcset=""
              ></img>
              <div className="small-padding"></div>
              <h2 className="TitleText">İstinyePark Teras Şube</h2>

              <br />
           

              <div className="flex ">
              <a onClick={
                  () => {
                    window.open('https://www.google.com/maps?sca_esv=ba085665fbb45bd4&sca_upv=1&gs_lp=Egxnd3Mtd2l6LXNlcnAiBmlzdGlueSoCCAAyEBAAGIAEGLEDGEMYgwEYigUyDRAAGIAEGLEDGEMYigUyCxAuGIAEGMcBGK8BMgsQABiABBixAxiDATILEAAYgAQYsQMYgwEyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAAGIAEGLEDGIMBMgsQLhiABBixAxiDAUjDKFDxAlifJHAEeACQAQCYAdgBoAGdDaoBBTAuOS4xuAEDyAEA-AEBmAINoALCDagCCsICBRAhGKABwgIWEAAYAxi0AhjlAhjqAhiMAxiPAdgBAcICFhAuGAMYtAIY5QIY6gIYjAMYjwHYAQHCAgoQABiABBhDGIoFwgIQEC4YgAQYQxjHARiKBRivAcICDhAuGIAEGLEDGIMBGIoFwgIFEC4YgATCAggQABiABBixA8ICDRAuGIAEGLEDGEMYigXCAg4QLhiABBixAxjRAxjHAZgDA4gGAboGBAgBGAqSBwUzLjkuMaAH5Vw&um=1&ie=UTF-8&fb=1&gl=tr&sa=X&geocode=KWMowr0S3bsUMfBganAtG3Ag&daddr=BAH%C3%87ELERARASI+MH+%C5%9EEH%C4%B0T+B%C4%B0NBA%C5%9EI+AL%C4%B0+RESM%C4%B0+TUFAN+SOK.+NO:3,+35330+Bal%C3%A7ova/%C4%B0zmir', '_blank');
                  }
                }>
                <div className="flexCenter button">
                  <MdLocationOn />
                  <div className="smallest-padding"></div>
                  Konuma Git
                </div>
              </a>
              <div className="small-padding"></div>
              <a href="tel:+905059229212">
                <div className="flexCenter button-white">
                  <MdPhone />
                  <div className="smallest-padding"></div>
                  İletişim
                </div>
              </a>
              </div>
           
             
            </div>
          </div>
        </div>

        </div>
    );
}