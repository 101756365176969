import React, { useState } from "react";
import "./modal.css";


import {
    MdOutlineArrowDropDown,
    MdLocationPin,
  } from "react-icons/md";

export default function Modal() {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) { 
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      {/* <button onClick={toggleModal} className="btn-modal">
        Open
      </button> */}

<div 
onClick={toggleModal}
className="flexStart search-bar">
      <div className=" flex">
      <MdLocationPin color="black" size={25} />
   <h4>Etkinlik Biletleri için Şehir Seçin</h4>
      </div>
      <MdOutlineArrowDropDown size={20} color="black" />
    </div>

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <h2>Şehir Seç</h2>
            <div className=" flexColStart ">
              <div className="small-padding"></div>
            <a className="citylinkbutton"
            onClick={() => {
              window.location.href =
                "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-izmir";
            }}
            >
              İzmir
              <span>35</span>
            </a>
            <a className="citylinkbutton"
             onClick={() => {
              window.location.href =
                "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-ankara";
            }}
            >
              Ankara
              <span>06</span>
            </a>
            <a className="citylinkbutton"
             onClick={() => {
              window.location.href =
                "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-mersin";
            }}
            >
              Mersin
              <span>33</span>
            </a>
            <a  className="citylinkbutton"
            onClick={() => {
              window.location.href =
                "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-aydin";
            }}
            >
              Aydın
              <span>09</span>
            </a>
            <a className="citylinkbutton"
            onClick={() => {
              window.location.href =
                "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-manisa";
            }}
            >
              Manisa
              <span>45</span>
            </a>
            <a className="citylinkbutton"
             onClick={() => {
              window.location.href =
                "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-balikesir";
            }}
            >
              Balıkesir
              <span>10</span>
            </a>
            <a className="citylinkbutton"
            onClick={() => {
              window.location.href =
                "https://biletinial.com/tr-tr/egitim/workshop-etkinlik-takvimi-sosyal-sanathane-fethiye";
            }}
            >
              Fethiye
              <span>48</span>
            </a>
            </div>
            <button className="close-modal" onClick={toggleModal}>
              &times;
            </button>
          </div>
        </div>
      )}
    
    </>
  );
}