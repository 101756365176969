
import logo from '../../assets/img/sosyal_light.png'; // Update the path href your logo
import React, { useState } from "react";
import MdOutlineAddShoppingCart from "react-icons/md";
import "./header.css";
import { Link, a } from "react-router-dom";


// JavaScript fonksiyonu
const addBlurHeader = () => {
  const header = document.getElementById("header");
  window.scrollY >= 50
    ? header.classList.add("blur-header")
    : header.classList.remove("blur-header");
};


export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <header
    id="header"
    className="header innerWidth"
    onScroll={addBlurHeader}
    >
    <nav>
      <a href="/" className="title">
        <img src={logo} width={100} alt="" />
      </a>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <a href="/#etkinlikler" >Etkinlikler</a>
        </li>
        <li>
          <a href="/#hakkimizda"  >Hakkımızda</a>
        </li>
        <li>
          <a href="/#mekanlar"  >Mekanlar</a>
        </li>
        <li>
          <a href="/#subeler"

          >Şubeler</a>
        </li>
        <li>
          <a href="/#sss"

          >SSS</a>
        </li>
        <li>
          <a href="/#mobil"

          >Mobil Uygulama</a>
        </li>
        <li>
          <a 
          href="/#iletisim"  
          >İletişim</a>
        </li>
        <li className=''>
          <div className='button' onClick={
            () => {
              window.open('https://shrefre.sosyalsanathane.com', '_blank');
            }
          
          }>
          Mağaza
          </div>
        </li>
      </ul>
    </nav>
  </header>
  );
}



