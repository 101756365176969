import { getDate, getLowestPrive } from "../functions/date";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";


import { getDoc } from "firebase/firestore";
import { db } from "../config";
import { useEffect } from "react";
import { doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "./../sections/header/header";
export default function Purchase() {
  let navigate = useNavigate();

  const location = useLocation();
  const event = location.state;
  const tickets = event.eventDetail;
  const areaCode = event.area; // This *should* come from event object
  const [prices, setPrices] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);

  const getPrices = async () => {
    await getDoc(doc(db, "franchises", areaCode)).then((doc) => {
      const data = doc.data();
      setPrices(data.prices);
    });
  };

  useEffect(() => {
    getPrices();
  }, []);

  return (
    <>
      <Header />
      <section className="home section  paddings innerWidth">
        <div className="container">
          <div className="progress-container">
            <div
              className="progress-bar"
              style={{
                width: "50%",
              }}
            >
              <span className="progress-value">{}</span>
            </div>
          </div>
          <div className="avatar-under-progress2">
            <h2>2</h2>
          </div>
          <h4 className="under-avatar-progress-text2">Bilet Detayları</h4>
        </div>

        <div className="home__con2 container ">
          <div className="">
            {tickets.map((ticket) => {
              return (
                <div className="tile">
                  <div className="justify-content">
                    <span className="">
                      <div className="">
                        <ul>
                          <li>

                <h4 className="uppercase">
                {ticket} workshop
                </h4>
                        

                          </li>
                          <li>
                            <span className="">
                              <span className="price-text">
                                {prices[ticket]} ₺
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </span>

                    <span className="event-name   "></span>
                    <div>
                      {selectedTickets.includes(ticket) ? (
                        <div className="flex">
                          <button
                            className="btn"
                            onClick={() => {
                              setSelectedTickets(
                                selectedTickets.filter(
                                  (selectedTicket) => selectedTicket !== ticket
                                )
                              );
                            }}
                          >
                            <span>
                              <i className="ri-subtract-line" />
                            </span>
                          </button>
                          <div className="smallest-padding"></div>
                          <h3 className="smallest-padding">
                            {
                              selectedTickets.filter(
                                (selectedTicket) => selectedTicket === ticket
                              ).length
                            }
                          </h3>
                          <div className="smallest-padding"></div>
                          <button
                            className="btn"
                            onClick={() => {
                              setSelectedTickets([...selectedTickets, ticket]);
                            }}
                          >
                            <span>
                              <i className="ri-add-line" />
                            </span>
                          </button>
                        </div>
                      ) : (
                        <button
                          className="btn"
                          onClick={() => {
                            setSelectedTickets([...selectedTickets, ticket]);
                          }}
                        >
                          <span className="">Ekle</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="home__data">
            <div className="filterContainer">
              <div className="">
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  Satın Alım Detayları
                </h2>

                <span class="">
                  <div className="">
                    <div className="small-padding"></div>
                    <div>
                      <h4>{event.name}</h4>
                      <p>
                        {event.address
                          ? event.address
                          : "Adres bilgisi bulunmamaktadır"}
                      </p>
                      <div className="smallest-padding"></div>
                      <p>
                        {getDate(event.date).dayNumber}{" "}
                        {getDate(event.date).monthName}{" "}
                        {getDate(event.date).dayName} | {event.startTime} -{" "}
                        {event.endTime}
                      </p>{" "}
                      <div className="smallest-padding"></div>
                      <div className="">
                        <label>
                          {event.minAge === ""
                            ? "Yaş kısıtlaması bulunmamaktadır"
                            : event.minAge && event.minAge + " yaş ve üzeri"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="small-padding"></div>
                </span>
                <div className="small-padding"></div>

                <h3>
                  {selectedTickets.length == 0
                    ? "Henüz bilet seçmediniz"
                    : "Seçilen Biletler"}
                </h3>
                <div className="small-padding"></div>

                <div className=" justify-between items-center">
                  <ul>
                    {Array.from(new Set(selectedTickets)).map((ticket) => {
                      const count = selectedTickets.filter(
                        (selectedTicket) => selectedTicket === ticket
                      ).length;
                      return (
                        <>
                          {/* make list item column*/}
                          <li>
                            <div className=" justify-between">
                              <span className="price-text ">{count} x </span>
                              <p className=" event-name-small">
                                {ticket} workshop{" "}
                              </p>
                              <p className="">₺ {prices[ticket] * count}</p>
                            </div>
                            <br />
                          </li>
                        </>
                        
                      );
                    })}
                  </ul>
                </div>
                <br />
                <span className="justify-content-end">
                  <span className="price-text">Toplam</span>
                  <span className="price-text-bold">
                    {Array.from(new Set(selectedTickets)).reduce(
                      (acc, ticket) => {
                        const count = selectedTickets.filter(
                          (selectedTicket) => selectedTicket === ticket
                        ).length;
                        return acc + prices[ticket] * count;
                      },
                      0
                    )}{" "}
                    ₺
                  </span>
                  <span className="price-text ">KDV DAHİL</span>
                </span>
                <br />
              </div>
              <div
                className="home__buttons justify-content-end"
                style={{
                  pointerEvents: selectedTickets.length == 0 ? "none" : "",
                }}
              >
                <Link
                  className="button"
                  to="/odeme"
                  state={{
                    event: event,
                    selectedTickets: selectedTickets,
                    totalPrice: Array.from(new Set(selectedTickets)).reduce(
                      (acc, ticket) => {
                        const count = selectedTickets.filter(
                          (selectedTicket) => selectedTicket === ticket
                        ).length;
                        return acc + prices[ticket] * count;
                      },
                      0
                    ),
                    prices: prices,
                  }}
                >
                  SONRAKİ ADIM
                </Link>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
}
