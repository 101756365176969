import { useContext } from 'react';
import sosyal_light from '../assets/img/sosyal_light.png';
import { UserContext } from '../index.js';
import avatar from '../assets/img/placeholder.png';




export default function Header() {
    return <>
        <header className="header" id="header">
            <nav className="nav container">
                <a  className="nav__logo">
                    <a  onClick={() => {
                        window.location.href = 'https://www.sosyalsanathane.com';
                    }}>
                        <img
                            className="nav__logo"
                            src={sosyal_light}
                            alt=""
                            srcSet=""
                            width={100}
                        />
                    </a>
                </a>
                
                <div className="nav__actions">
                    {/* <a
                        href="https://ai.sosyalsanathane.com"
                        target="_blank"
                        className="button__link"
                    >
                        <h6>image.ai</h6>
                    </a> */}
                    <button className='button' 
                    onClick={() => {
                        window.location.href = 'https://store.sosyalsanathane.com';
                    }}
                    > MAĞAZA</button>

                    {/* Toggle button */}
                    <div className="nav__toggle" id="nav-toggle">
                        <i className="ri-menu-line" />
                    </div>
                </div>
            </nav>
        </header>

    </>

}


function LoginBtn() {
    const user = useContext(UserContext);

    if (user) {
        return <a href='#'>
        <div class="avatar">
  <image src={avatar}
  alt="" ></image>
</div> </a>
    } else {
        return <a className='btn' href="/kayit-ol">Kayıt Ol</a>;
    }
}