import Footer from "./footer";
import Header from "./header";
import React, { useState } from 'react';
import { signInWithPhoneNumber } from "firebase/auth";
import { Button, Form, Input } from "antd";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { fetchSignInMethodsForEmail, RecaptchaVerifier } from "firebase/auth";
import { auth } from '../config';


export default function Signup() {
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [user, setUser] = useState(null);



    const sendVerificationCode = async () => {
        setLoading(true);

        try {
            const email = convertPhoneToEmail(phone);
            const methods = await fetchSignInMethodsForEmail(auth, email);

            if (methods.includes("phone")) {
                alert("Bu telefon numarası zaten kullanımda. Giriş yapın.");
                setLoading(false);
                return;
            }

            const catptcha = new RecaptchaVerifier(auth, 'captcha', {});
            const result = await signInWithPhoneNumber(auth, phone, catptcha);
            setUser(result);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const verifyCode = async () => {
        setLoading(true);

        try {
            
            console.log(code);
            const credential = await user.confirm(code);
            console.log(credential);
            setLoading(false);
            

        } catch (error) {
            setLoading(false);
            console.error(error);
        }

    };



    return <>
        <Header />
        <section className="login__section section" id="signup">
            <div className="login__container   ">
                {user ? <Form
                    name="code"
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    onFinish={verifyCode}
                    autoComplete="off"
                >
                    <Form.Item name="code">
                        <Input
                            placeholder="Onay Kodu"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </Form.Item>
                    <Button 
                     style={{ marginTop: "10px", backgroundColor: "#9bc5ac", color: "#000"}}
                    id="code-button" type="primary" htmlType="submit" block loading={loading}>
                        Onayla
                    </Button>
                </Form> : <Form
                    name="basic"
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    onFinish={sendVerificationCode}
                    autoComplete="off"
                >
                    <Form.Item name="phone">
                        <PhoneInput
                            defaultCountry="TR"
                            placeholder="Telefon Numarası"
                            value={phone}
                            onChange={setPhone}

                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 24, }}>
                        <Button 
                            style={{ marginTop: "10px", backgroundColor: "#9bc5ac", color: "#000"}}
                        id="sign-in-button" type="primary" htmlType="submit" block loading={loading}>
                            Onay Kodu Gönder
                        </Button>
                        {user == null ? <div id="captcha" style={{ marginTop: "10px" }}></div> : null}
                    </Form.Item>
                </Form>}
            </div>
        </section>
        <Footer />
    </>
}

export const convertPhoneToEmail = (phone) => {
    console.log(phone);
    const cleanedPhone = phone
        .replace("(", "A")
        .replace(")", "B")
        .replace(",", "C")
        .replace("#", "D")
        .replace(" ", "E");

    const email = `${cleanedPhone}@sosyalsanathane.com`;

    return email;
};